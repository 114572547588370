/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}
body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}
p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
img {
  width:70%;
  height:100%;
}
button {
  border: 0;
  background-color: transparent;
}
input:focus, textarea:focus, select:focus {
  outline: none;
}
p.success {
  color: green;
}
p.danger {
  color: red;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 17px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
    line-height: 1;

    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  margin-right: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}
.language-icon{
  max-width: 30px;
  width: 100%;
  height: auto;
  margin-left: 10px;
}
@media (max-width: 420px) {
  nav.navbar .navbar-nav .nav-link.navbar-link {
    padding: 5px 25px;
    font-size: 17px;
    text-align: center;
  }
  nav.navbar.initial-black {
    background-color: #121212;
  }
  .social-icon {
    margin-left: 36%;
  }
  .navbar-text button {
    padding: 12px 20px;
    font-size: 17px; 
    margin-left: 27%;
    margin-bottom: 10px;
  }
  .language-icon {
    max-width: 30px;
    width: 100%;
    height: auto;
    margin-left: 260%;
    margin-right: 20px;
    padding-bottom: 10px;
  }
  .language-link  {
    max-width: 30px;
    width: 100%;
    height: auto;
    margin-left: 30%;
    margin-right: 20px;
    padding-bottom: 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .navbar-text button {
    padding: 12px 20px;
    font-size: 15px; 
    margin-bottom: 8px;
  }
}
/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  width: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(255,164,89, 0.5) -5.91%, rgba(255,102,196, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
@media (max-width: 420px) {
  .banner {
    padding: 150px 0 50px 0;
  }
  .banner h1 {
    font-size: 35px;
    margin: 30px 10px;
  }
  .banner p {
    font-size: 14px;
    margin: 30px 10px;
    text-align: justify;
  }
  .banner img {
    animation: updown 3s linear infinite;
    width: 50%;
    display: block;
    margin: 0 auto;
  }
  .banner .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(255,164,89, 0.5) -5.91%, rgba(255,102,196, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 18px;
    margin: 10px 10px;
    display: inline-block;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .banner {
    padding: 230px 0 130px 0;
  }
  .banner h1 {
    font-size: 38px;
  }
  .banner p {
    font-size: 17px;
  }
}
/************ AboutMe Css ************/
.aboutme {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.aboutme h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.aboutme p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.aboutme .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.aboutme .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.aboutme .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.aboutme .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #ffa459 -5.91%, #9c3f78 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.aboutme .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.aboutme .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#aboutme-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#aboutme-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#aboutme-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.aboutme-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin: 30px 0;
  width: 400px;
  height: 250px;
}
.aboutme-imgbx img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutme-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #ffa459 -5.91%, #9c3f78 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.aboutme-imgbx:hover::before {
  height: 100%;
}
.aboutme-txtx {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.aboutme-imgbx:hover .aboutme-txtx {
  top: 50%;
  opacity: 1;
}
.aboutme-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.aboutme-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
@media (max-width: 420px) {
  .aboutme {
    padding: 60px 0;
  }
  .aboutme h2 {
    font-size: 30px;
  }
  .aboutme p {
    font-size: 15px;
    margin: 20px auto 30px auto;
    width: 100%;
  }
  .aboutme .nav.nav-pills .nav-link {
    font-size: 15px;
  }
  .aboutme-imgbx {
    margin: 10px auto;
    width: 350px;
    height: 150px;
  }
  .aboutme-txtx h4 {
    font-size: 17.5px;
  }
  .aboutme-txtx span {
    font-size: 13px;
    letter-spacing: 0.2px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .aboutme h2 {
    font-size: 43px;
  }
  .aboutme p {
    font-size: 17px;
    width: 70%;
  }
  .aboutme-imgbx {
    margin: 10px auto;
    width: 350px;
    height: 230px;
  }
  .aboutme-txtx h4 {
    font-size: 25px;
  }
  .aboutme-txtx span {
    font-size: 14.5px;
  }
}

/************ Caroussel Css ************/
.carousel-container {
  overflow: hidden;
  width: 100%;
  margin: 20px;
}
.react-multi-carousel-list {
  display: flex;
  transition: transform 0.5s ease;
}
.react-multi-carousel-item {
  flex: 0 0 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 15px;
}
.react-multi-carousel-item img {
  max-width: 500px;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 20px;
}
.react-multi-carousel-dot-list {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.react-multi-carousel-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  background-color: #fcc197;
}
.react-multi-carousel-dot--active {
  background-color: #ac5073;
}
.carousel-control-prev {
  background-color: #9c3f78;
  color: #fcc197;
}
.react-multi-carousel__arrow--right {
  background-color: #9c3f78;
  color: #fcc197;
}
.carousel-container a {
  background-image: linear-gradient(90.21deg, #ffa459 -5.91%, #9c3f78 111.58%);
  background-clip: text;
  color: transparent;
  text-decoration: none;
  font-size: 32px;
  font-weight: bold;
}
.carousel-container h4 {
  font-size: 17px;
  color: #bf636d;
  font-style: italic;
}
.carousel-container p {
  font-size: 16px;
  color: #ffa459;
}
@media (max-width: 420px) {
  .carousel-container {
    width: 80%;
    margin: auto;
  }
  .react-multi-carousel-item img {
    max-width: 300px;
    display: block;
    margin: 0 23px;
    margin-bottom: 5%;
  }
  .carousel-container a {
    font-size: 28px;
    margin-right: 13px;
  }
  .carousel-container h4 {
    font-size: 13.5px;
    display: block;
    margin: 0px 28px;
    width:80%;
  }
  .carousel-container p {
    font-size: 13px;
    display: block;
    width:70%;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .react-multi-carousel-item img {
    max-width: 400px;
  }
  .carousel-container a {
    font-size: 28px;
  }
  .carousel-container h4 {
    font-size: 16px;
  }
  .carousel-container p {
    font-size: 16px;
    display: block;
    width:70%;
  }
}

/************ Hobbies Css ************/
.hobbies-bx {
  text-align: center;
  padding: 60px;
  margin-top: 0px;
}
.hobby-image  {
  width: 45%;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .hobby-image  {
    width: 32%;
  }
}

/************ Skills Css ************/
.skill {
  padding: 0 0 100px 0;
  position: relative;
  background-image: url("./assets/img/skill_bckgrd.png"); 
  background-size: cover; 
  background-repeat: no-repeat;
}
.skill-bx {
  text-align: center;
  padding: 60px;
  margin-top: 0px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 28px 0 0px 0;
}
.flex {
  display: flex;
  flex-wrap: nowrap; 
}

.scroller {
  max-width: 1500px;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.scroller__inner img {
  width: 100px;
  height: 100px;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 40s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
@media (max-width: 420px) {
  .skill {
    padding: 0px 0;
  }
  .skill-bx {
    padding: 30px;
  }
  .skill h2 {
    font-size: 30px;
  }
  .skill p {
    font-size: 15px;
    margin: 20px auto 0px auto;
  }
  .scroller__inner img {
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .skill h2 {
    font-size: 43px;
  }
  .skill p {
    font-size: 17px;
  }
  .scroller__inner img {
    width: 80px;
    height: 80px;
  }
}

/************ CTF Css ************/
.ctf {
  background-image: url("./assets/img/ctf_bckgrd.png"); 
  background-size: cover; 
  background-repeat: no-repeat;
  padding: 0px 0 200px 0;
}
.ctf-bx {
  color: white;
  text-align: center;
  padding: 60px;
  margin-top: 0px;
}
.ctf-img {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap; 
}
.ctf-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 0 70px 30px 0;
}
.ctf h2 {
  color: white;
	font-size: 45px;
	font-weight: 700;
}
.ctf p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 10px 0 0px 0;
}
.ctf-link-chall a{
  color: #ffffff;
  text-decoration: none;
  margin: 0 70px 30px 0;  
}
.ctf-link-chall a:hover {
  color: #b97543;
}
.ctf-link-ctf a{
  color: #ffffff;
  text-decoration: none;
  margin: 0 70px 30px 0;
}
.ctf-link-ctf span{
  color: #b97543;
  text-decoration: none;
  margin: 0 70px 30px 0;
}
.ctf-link-ctf a:hover {
  color: #b97543;
}
@media (max-width: 420px) {
  .ctf {
    padding: 0px 0;
  }
  .ctf-bx {
    padding: 40px 0px;
    margin-top: 10px;
  }
  .ctf h2 {
    font-size: 30px;
  }
  .ctf p {
    font-size: 15px;
    margin: 20px auto 0px auto;
  }
  .ctf-img-chall img {
    margin: 0px auto;
    padding-left: 0px;
    margin-bottom: 10px;
    width: 50px;
    height: 50px;
  }
  .ctf-img-ctf img {
    margin: 0px auto;
    padding-left: 0px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    width: 50px;
    height: 50px;
  }
  .ctf-link-chall a{
    color: #ffffff;
    text-decoration: none;
    margin: 0px 10px;
    font-size: 14px;
  }
  .ctf-link-ctf a{
    color: #ffffff;
    text-decoration: none;
    padding-left: 0px;
    margin: 0px auto;
    font-size: 14px;
  }
  .ctf-link-ctf span{
    color: #b97543;
    text-decoration: none;
    padding-left: 0px;
    margin: 0px auto;
    font-size: 14px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .ctf h2 {
    font-size: 43px;
  }
  .ctf p {
    font-size: 17px;
  }
  .ctf-img-chall img {
    width: 100px;
    height: 100px;
  }
  .ctf-img-ctf img {
    margin: 0px auto;
    margin-left: 15px;
    margin-right: 40px;
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
  }
  .ctf-link-chall a{
    font-size: 17px;
  }
  .ctf-link-ctf a{
    margin: 0px auto;
    margin-left: 15px;
    margin-right: 40px;
    margin-bottom: 10px;
    font-size: 17px;
  }
  .ctf-link-ctf span{
    margin: 0px auto;
    margin-left: 15px;
    margin-right: 40px;
    margin-bottom: 10px;
    font-size: 17px;
  }
}

/************ Footer Css ************/
.footer {
  padding: 0 0 25px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer img {
  width: 14%;
  margin-top: 5%;
}
.footer-icon{
  margin-top: 5%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 40px;
}
.footer a {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .footer img {
    width: 10%;
    margin-top: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-icon {
    margin-left: -50%;
    margin-top: 10px;
  }
  .footer p {
    margin-top: 5%;
  }
}
